<template>
    <div>
        <template v-if="frList != null && frList.length > 0">
            <div class="fr-items" v-for="( item, index ) in  frList " v-bind:key="item.id">
                <div class="fr-items-inner">
                    <div style="clear: both;height: 10px;width: 100%;"></div>
                    <div>
                        <font style="color: #4169E1;margin-right: 5px;">[{{ item.gender === 1 ? '男' : '女' }}生]</font>{{
                            item.schoolName }}
                    </div>
                    <div class="fr-item-line"></div>
                    <div style="margin-top: 6px;" v-if="item.qqNumber">QQ {{ item.qqNumber }}</div>
                    <div style="margin-top: 10px;" v-if="item.wxQrcode">
                        <img v-for="url in item.wxQrcodeList" :src="url" v-bind:key="url"
                            style="height: 100px;margin-right: 5px;cursor: pointer;" @click="onClickYulanImgUrl(url)" />
                    </div>
                    <div style="margin-top: 6px;color: #666;" v-if="item.note">{{ item.note }}</div>
                    <div style="margin-top: 6px;">我留的微信号：{{ item.wxNumber }}</div>
                    <div class="fr-item-line"></div>
                    <div style="margin-top: 10px;font-size: 13px;">
                        <table style="width: 100%;">
                            <tr>
                                <td>
                                    <div style="color: #999;">已被{{ item.readNum }}人抽到</div>
                                    <div style="color: red;font-size: 12px;margin-top: 3px;">如果微信/QQ留错了,请销毁</div>
                                </td>
                                <td>
                                    <div class="fr-item-delete-btn" @click="onclickDelPaper(item.id, index)">销毁</div>
                                </td>
                            </tr>
                        </table>

                    </div>
                    <div style="clear: both;height: 15px;width: 100%;"></div>
                </div>
            </div>
            <div style="height: 1px;width: 100%;"></div>
        </template>
        <template v-else>
            <div style="height: 200px;width: 100%;font-size: 13px;margin-top: 20px;color: #999;">你还没有放入过交友纸条</div>
        </template>

        <div v-if="yulanImgUrlDialogShow"
            style="width: 100%;height: 100%;position: fixed;top: 0px;left: 0px;z-index: 999999;background-color: #333;overflow: hidden;">
            <div style="height: 40px;color: white;text-align: right;margin-top: 15px;margin-right: 15px;">
                <span style="padding: 2px 4px;background-color: red;cursor: pointer;"
                    @click="yulanImgUrlDialogShow = false">关闭</span>
            </div>
            <div style="text-align: center;">
                <img :src="yulanImgUrl" style="max-width: 400px;max-height: 600px;" />
            </div>
        </div>
    </div>
</template>

<style scoped>
.fr-items {
    text-align: left;
    font-size: 14px;
    width: 94%;
    margin-left: 3%;
    margin-top: 10px;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
}

.fr-items-inner {
    width: 92%;
    margin-left: 4%;
}

.fr-item-line {
    height: 1px;
    background-color: #eee;
    width: 100%;
    margin-top: 10px;
}

.fr-item-delete-btn {
    width: 45px;
    height: 25px;
    line-height: 25px;
    background-color: #FF6347;
    color: white;
    text-align: center;
    float: right;
    border-radius: 25px;
    cursor: pointer;
}
</style>

<script>
import { queryUserPaperIncreaseList, userPaperIncreaseDelete } from '@/api/phone/paper'
import { showConfirmDialog } from 'vant'

export default {
    name: "MyFr",
    components: {

    },
    props: {
        openid: {
            default: "",
            type: String
        }
    },
    data() {
        return {
            frList: [],
            yulanImgUrl: "",
            yulanImgUrlDialogShow: false
        }
    },
    created() {
        this.initCurrPage();
    },
    methods: {
        initCurrPage() {
            queryUserPaperIncreaseList({ openid: this.openid }).then(res => {
                this.frList = res.data
                for (const key in this.frList) {
                    let element = this.frList[key];
                    if (element.wxQrcode) {
                        this.frList[key].wxQrcodeList = element.wxQrcode.split(",")
                    }
                }
            })
        },
        onClickYulanImgUrl(url) {
            this.yulanImgUrl = url
            this.yulanImgUrlDialogShow = true
        },
        onclickDelPaper(id, index) {
            showConfirmDialog({
                title: '操作提示',
                message:
                    '确定要销毁该纸条吗？',
            }).then(() => {
                //确认销毁
                userPaperIncreaseDelete({ id: id, openid: this.openid }).then(res => {
                    if (res.code === 200) {
                        this.frList.splice(index, 1)
                    }
                })
            })
                .catch(() => {
                    // on cancel
                });
        }
    }
}
</script>