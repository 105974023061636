import request from '@/utils/request'

export function countByOpenid(query) {
    return request({
        url: '/h5/client/paper/countByOpenid',
        method: 'get',
        params:query,
        T:"P"
    })
}


export function queryUserPaperIncreaseList(query) {
    return request({
        url: '/h5/client/userPaper/increase/list',
        method: 'get',
        params:query,
        T:"P"
    })
}

export function userPaperIncreaseDelete(paras) {
    return request({
        url: '/h5/client/userPaper/increase/delete',
        method: 'post',
        data: paras,
        T:"P"
    })
}

export function queryUserPaperExtractList(query) {
    return request({
        url: '/h5/client/userPaper/extract/list',
        method: 'get',
        params:query,
        T:"P"
    })
}

export function userPaperExtracDelete(paras) {
    return request({
        url: '/h5/client/userPaper/extract/delete',
        method: 'post',
        data: paras,
        T:"P"
    })
}

export function userPaperExtracUpdateStatus(paras) {
    return request({
        url: '/h5/client/userPaper/extract/updateStatus',
        method: 'post',
        data: paras,
        T:"P"
    })
}

export function userPaperExtracReset(paras) {
    return request({
        url: '/h5/client/userPaper/extract/reset',
        method: 'post',
        data: paras,
        T:"P"
    })
}