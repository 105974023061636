<template>
    <div>
        <template v-if="shopInfo && shopInfo.id">
            <div class="asheng-card">
                <table class="asheng-index-top-main-table">
                    <tr>
                        <td style="width: 70px;">
                            <div class="asheng-index-top-main-userinfo-img">
                                <img src="/default.jpeg" style="width:56px;height: 56px;" />
                            </div>
                        </td>
                        <td>
                            <div>
                                <div class="userinfo-user-name">已绑定{{ shopInfo.phone ? shopInfo.phone : '' }}</div>
                            </div>
                            <div style="height: 1px;width: 100%;clear: both;"></div>
                            <div class="userinfo-no-read-name" v-if="shopInfo.status == 0">已激活</div>
                            <div class="userinfo-no-read-name" @click="orderResetPay"
                                style="cursor: pointer; background-color: #ccc;" v-else>点我去激活店铺</div>
                        </td>
                    </tr>
                </table>


                <table class="asheng-index-top-main-table" style="margin-top: 20px;">
                    <tr>
                        <td class="asheng-index-top-main-sy">
                            <div class="asheng-sy-price">{{ shopInfo.sacnUserCount ? shopInfo.sacnUserCount : 0 }}人</div>
                            <div class="asheng-sy-price-text">推广的用户数</div>
                        </td>
                        <td class="asheng-index-top-main-sy" @click="onclickTiXian" style="cursor: pointer;">
                            <div class="asheng-sy-price">{{ shopInfo.shopCashFee ? shopInfo.shopCashFee : 0 }}元
                            </div>
                            <div class="asheng-sy-price-text">余额<font style="font-size: 11px;">(点金额提现)</font>
                            </div>
                        </td>
                        <td class="asheng-index-top-main-sy">
                            <div class="asheng-sy-price">{{ shopInfo.shopTotalFee ? shopInfo.shopTotalFee : 0 }}元</div>
                            <div class="asheng-sy-price-text">累计收益</div>
                        </td>
                    </tr>
                </table>

                <div style="width: 100%;height: 15px;"></div>
            </div>

            <div style="width: 94%;margin-left: 3%;text-align: left;margin-top: 15px;background-color: white;">
                <div style="width: 94%;margin-left: 3%;text-align: left;padding-bottom: 20px;padding-top: 10px;">
                    <div>
                        <p style="font-size: 13px;">
                            <font style="font-weight: bold;">
                                必须扫你的推广码的用户消费，才有60%的提成。分享链接等途经无效。
                            </font>
                        </p>
                    </div>
                    <div @click="shareShopDlglog = true" class="custom-show-share-qrcode"><van-icon name="qr"
                            style="font-size: 15px;color: white;margin-right: 3px;" />立即生成我的推广码
                    </div>
                </div>
            </div>

            <div style="height: 1px;width: 100%;clear: both;"></div>

            <div class="menus-div" style="width: 94%;margin-left: 3%;">
                <div class="menus-title" style="text-align: left;">推广管理</div>
                <table class="menus-table menus-table-text-center">
                    <tr>
                        <td class="asheng-tcgl-td" @click="queryTiXianRec">
                            <van-icon name="balance-pay" style="font-size: 30px;color: rgb(195, 0, 255);" />
                            <div class="asheng-tcgl-td-text">提现记录</div>
                        </td>

                        <td></td>
                        <td class="asheng-tcgl-td" @click="queryFinanceRec">
                            <van-icon name="bill-o" style="font-size: 30px;color: rgb(255, 98, 0);" />
                            <div class="asheng-tcgl-td-text">账户明细</div>
                        </td>

                        <td></td>
                        <td class="asheng-tcgl-td" @click="queryXiajiShop">
                            <van-icon name="shop-collect-o" style="font-size: 30px;color: rgb(0, 255, 85);" />
                            <div class="asheng-tcgl-td-text">下级分店</div>
                        </td>

                        <td></td>
                        <td class="asheng-tcgl-td" @click="addSchoolInfo">
                            <van-icon name="diamond-o" style="font-size: 30px;color: rgb(195, 0, 255);" />
                            <div class="asheng-tcgl-td-text">添加学校</div>
                        </td>

                    </tr>
                </table>
                <div style="height: 15px;width: 100%;clear: both;"></div>
            </div>

        </template>
        <template v-else>
            <div class="top-msg">* 只需填手机号和密码，即可开通店铺赚钱</div>
            <div>
                <van-cell-group inset>
                    <!-- <van-field label-width="100" v-model="form.shopName" label="店铺名" placeholder="请填写店铺名" /> -->
                    <van-field label-width="60" v-model="form.phone" label="手机号" placeholder="请填写手机号" />
                    <van-field label-width="60" v-model="form.password" label="密码" placeholder="请填写密码" />
                    <div style="height: 80px;margin-top: 15px;">
                        <van-button round type="primary" style="width: 94%;" @click="addShop">马上开通</van-button>
                    </div>
                </van-cell-group>
            </div>
        </template>

        <!-- 分享店铺二维码图片 -->
        <div v-if="shareShopDlglog" id="share-qrcode-dlglog" class="asheng-custom-dlglog-inner"
            style="width:350px;z-index: 9999;background: none;overflow-y: auto;">
            <div ref="shopshareqrcodecontent" v-if="shareShopDlglog">
                <tg-share-image :id="shopInfo.id"></tg-share-image>
            </div>
            <div style="height: 5px;width: 100%;"></div>
            <!-- <div class="asheng-shop-share-qrcode-download" @click="converShopShareQrcodeToImage">
                <van-icon name="down" />
            </div> -->
        </div>
        <div class="asheng-custom-dlglog" v-if="shareShopDlglog" @click="shareShopDlglog = false"></div>
        <div id="canvas"></div>
        <!-- 分享店铺二维码图片 -->

        <!-- 提现处理 -->
        <van-popup v-model:show="showTiXianDlglog" round position="bottom" style="min-height: 60%;">
            <div style="height: 50px;line-height: 50px;font-weight: bold;border-bottom: 1px solid #eee;">提现</div>

            <div style="line-height: 22px;margin-left: 3%;width: 94%;margin-top: 10px;">
                <div style="line-height: 30px;color:red;font-size: 13px;">*
                    必须保证填写的zfb账号和姓名准确无误，才能提现成功。提现提交后，我们会尽快处理，最晚不超过24小时。</div>
                <div style="border-bottom: 1px solid #eee;line-height: 40px;font-weight: bold;">可提现余额：￥{{
                    shopInfo.shopCashFee ?
                    shopInfo.shopCashFee : 0 }}元
                </div>
                <van-field label-width="60" v-model="txform.amount" label="提现金额" placeholder="请填写提现金额" />
                <van-field is-link readonly label-width="60" v-model="txform.bankAccountText" label="提现账号"
                    placeholder="请选择提现账号" @click="showPicker = true" />
                <div @click="onclickAddBankAccount"
                    style="text-align: center;color: rgb(123, 0, 255);font-size: 13px;margin-top: 15px;cursor: pointer;">
                    还没有提现账号?立即添加</div>
                <div style="height: 80px;margin-top: 15px;">
                    <van-button round type="primary" style="width: 94%;" @click="liJiTiXian">立即提现</van-button>
                </div>
            </div>
        </van-popup>

        <van-popup v-model:show="showPicker" round position="bottom" style="min-height: 60%;">
            <template v-if="accountList != null && accountList.length > 0">
                <van-picker :columns-field-names="customFieldName" title="选择账号" :columns="accountList"
                    @cancel="showPicker = false" @confirm="onConfirm" />
            </template>

            <template v-else>
                <div style="height: 50px;line-height: 50px;font-weight: bold;border-bottom: 1px solid #eee;">选择账号</div>
                <div @click="onclickAddBankAccount"
                    style="cursor: pointer;text-align: center;color: rgb(123, 0, 255);font-size: 13px;margin-top: 15px;margin-bottom: 15px;">
                    还没有提现账号?立即添加</div>
            </template>
        </van-popup>

        <van-popup v-model:show="showAddBankAccount" round position="bottom" style="min-height: 60%;">
            <div style="height: 50px;line-height: 50px;font-weight: bold;border-bottom: 1px solid #eee;">添加账号</div>

            <div style="line-height: 22px;margin-left: 3%;width: 94%;margin-top: 10px;">
                <div style="line-height: 30px;color:red;font-size: 13px;">* 请准确填写提现账号信息，填写错误将导致提现失败</div>

                <van-field label-width="80" v-model="zhform.bankName" readonly label="开户行" placeholder="请填写开户行" />
                <van-field label-width="80" v-model="zhform.bankCardNumber" label="支付宝账号" placeholder="请填写支付宝账号" />
                <van-field label-width="80" v-model="zhform.bankAccountName" label="姓名" placeholder="请填写真实姓名" />
                <div style="height: 80px;margin-top: 15px;">
                    <van-button round type="primary" style="width: 94%;" @click="onclickAddBankAccountOk">保存</van-button>
                </div>

            </div>
        </van-popup>
        <!-- 提现处理 -->

    </div>
</template>

<script>
import html2canvas from 'html2canvas'
import { getShopByOpenid, getBankAccountList, addBankAccount, addCashApply } from '@/api/phone/shop'
import { showToast } from 'vant';
import TgShareImage from './TgShareImage.vue';
export default {
    name: "MyShop",
    components: {
        TgShareImage
    },
    props: {
        openid: {
            default: "",
            type: String
        }
    },
    data() {
        return {
            form: {},
            shopInfo: {},
            shareShopDlglog: false,
            showTiXianDlglog: false,
            accountList: [],
            showPicker: false,
            customFieldName: {
                text: 'name',
                value: 'id'
            },
            txform: {},
            zhform: {},
            showAddBankAccount: false
        }
    },
    created() {
        this.initCurrPage();
    },
    methods: {
        initCurrPage() {
            getShopByOpenid({ openid: this.openid }).then(res => {
                this.shopInfo = res.data
            })
        },
        addShop() {
            this.$emit('addShopOrder', this.form)
        },
        //订单重新支付
        orderResetPay() {
            this.form = { id: this.shopInfo.id }
            this.$emit('addShopOrder', this.form)
        },
        //点击提现
        onclickTiXian() {
            if (this.shopInfo.status == 0) {

                this.txform.amount = null
                this.accountList = []
                //查询银行账号
                getBankAccountList({ openid: this.openid }).then(res => {
                    for (const key in res.data) {
                        let element = res.data[key]
                        element.name = '[' + element.bankName + '] ' + element.bankCardNumber + '—' + element.bankAccountName
                        this.accountList.push(element)
                    }
                    if (this.accountList != null && this.accountList.length == 1) {
                        this.chooseBankAccountOk(this.accountList[0])
                    }
                    this.showTiXianDlglog = true
                })
            } else {
                showToast("激活店铺后可提现")
                return
            }
        },
        // 立即提现，提交提现申请
        liJiTiXian() {
            if (!this.txform.amount) {
                showToast("请填写提现金额")
                return
            }
            if (!this.txform.bankAccountId) {
                showToast("请选择提现账号")
                return
            }

            for (const key in this.accountList) {
                const element = this.accountList[key];
                if (element.id === this.txform.bankAccountId) {
                    this.txform.bankName = element.bankName
                    this.txform.bankAccountName = element.bankAccountName
                    this.txform.bankCardNumber = element.bankCardNumber
                    break;
                }
            }

            this.txform.openid = this.openid

            addCashApply(this.txform).then(res => {
                if (res.code == 200) {
                    showToast("提现申请提交成功，我们将在24小时内处理")
                    this.showTiXianDlglog = false
                    this.initCurrPage()
                    this.$router.push({ path: "/phone/txrec" })
                } else {
                    showToast(res.msg)
                }
            })
        },
        onclickAddBankAccount() {
            this.zhform = {
                bankName: "支付宝",
                openid: this.openid
            }
            this.showAddBankAccount = true
        },
        onclickAddBankAccountOk() {
            if (!this.zhform.bankAccountName) {
                showToast("账号不可为空")
                return
            }
            if (!this.zhform.bankCardNumber) {
                showToast("姓名不可为空")
                return
            }
            addBankAccount(this.zhform).then(res => {
                if (res.code === 200) {
                    this.showAddBankAccount = false
                    this.showPicker = false
                    //重新加载数据
                    this.onclickTiXian();
                    showToast("添加账号成功")
                } else {
                    showToast(res.msg)
                }
            })
        },
        onConfirm({ selectedOptions }) {
            console.info("选择", selectedOptions)
            this.chooseBankAccountOk(selectedOptions[0])
            this.showAddBankAccount = false
            this.showPicker = false
        },
        chooseBankAccountOk(a) {
            this.txform.bankAccountId = a.id
            this.txform.bankAccountText = '[' + a.bankName + '] ' + a.bankCardNumber + '—' + a.bankAccountName
        },
        addSchoolInfo() {
            this.$router.push({ path: "/phone/school" })
        },
        queryTiXianRec() {
            this.$router.push({ path: "/phone/txrec" })
        },
        queryFinanceRec() {
            this.$router.push({ path: "/phone/yjrec" })
        },
        queryXiajiShop() {
            this.$router.push({ path: "/phone/childshop", query: { rid: this.shopInfo.id } })
        },
        async converShopShareQrcodeToImage() {
            try {
                const canvas = await html2canvas(this.$refs.shopshareqrcodecontent)
                const image = canvas.toDataURL()
                this.downloadIamge(image)
            } catch (e) {
                //console.info(e)
            }
        },
        downloadIamge(imgsrc) {
            //下载图片地址和图片名
            var image = new Image()
            // 解决跨域 Canvas 污染问题
            image.setAttribute('crossOrigin', 'anonymous')
            image.onload = function () {
                let canvas = document.createElement('canvas')
                canvas.width = image.width
                canvas.height = image.height
                var context = canvas.getContext('2d')
                context.drawImage(image, 0, 0, image.width, image.height)
                var url = canvas.toDataURL('image/jpeg') //得到图片的base64编码数据

                var a = document.createElement('a') // 生成一个a元素
                var event = new MouseEvent('click') // 创建一个单击事件
                a.download = '推广图片' // 设置图片名称
                a.href = url // 将生成的URL设置为a.href属性
                a.dispatchEvent(event) // 触发a的单击事件
            }
            image.src = imgsrc
        },
    }
}
</script>

<style scoped>
.tx-rec-list {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
    height: 100%;
    width: 100%;
    background: linear-gradient(rgb(213, 234, 255), rgb(233, 240, 248));
}

.asheng-card {
    background-color: white;
    width: 94%;
    margin-left: 3%;
    margin-top: 10px;
    border-radius: 10px;
}

.top-msg {
    text-align: left;
    font-size: 13px;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 40px;
    color: red;
}

.asheng-shop-share-qrcode-download {
    font-size: 20px;
    font-weight: bold;
    color: red;
    width: 30px;
    height: 30px;
    background: #eee;
    line-height: 30px;
    border-radius: 30px;
    margin: 5px auto;
    cursor: pointer;
}

.asheng-index-top {
    width: 100%;
    background: linear-gradient(160deg, #cde0fa 5%, white);
    text-align: left;
}

.asheng-index-top-main {
    width: 94%;
    margin-left: 3%;
}

.asheng-index-top-main-table {
    width: 100%;
    padding-top: 15px;
}

.asheng-index-top-main-userinfo-img {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    overflow: hidden;
}

.userinfo-user-name {
    font-size: 15px;
    float: left;
    font-weight: bold;
}

.userinfo-no-read-name {
    margin-top: 10px;
    background: #ff625e;
    height: 20px;
    line-height: 20px;
    border-radius: 12px 12px 12px 0px;
    font-size: 14px;
    color: #ffffff;
    float: left;
    text-align: center;
    padding: 0px 8px;
}

.userinfo-user-phone {
    font-size: 13px;
    color: #666666;
    margin-top: 5px;
}

.asheng-index-top-main-sy {
    width: 33.3%;
    text-align: center;
}

.asheng-sy-price {
    height: 27px;
    font-size: 19px;
    font-weight: bold;
    color: #333333;
    line-height: 27px;
}

.asheng-sy-price-text {
    height: 19px;
    font-size: 14px;
    color: #666666;
    line-height: 19px;
    margin-top: 4px;
}

.asheng-index-top-main-ktx {
    background: url(../../../../assets/image/phone/txbj.png);
    background-size: 100%;
    margin-top: 23px;
    width: 100%;
    height: 60px;
    border-radius: 6px;
    overflow: hidden;
}

.asheng-ktx-table {
    width: 94%;
    margin-left: 3%;
    color: white;
}

.asheng-ktx-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    font-style: italic;
    letter-spacing: 2px;
}

.asheng-ktx-price-val {
    font-size: 13px;
    margin-top: 4px;
}

.asheng-ljtx-btn {
    width: 77px;
    height: 26px;
    line-height: 26px;
    background: #116ff0;
    border-radius: 14px;
    font-size: 13px;
    text-align: center;
    float: right;
    margin-top: 7px;
    cursor: pointer;
}

.asheng-tcgl {
    height: 21px;
    font-size: 15px;
    font-weight: bold;
    color: #333333;
    line-height: 21px;
    margin-top: 17px;
}

.asheng-tcgl-table {
    width: 96%;
    text-align: center;
    margin-left: 2%;
    margin-top: 10px;
}

.asheng-tcgl-td {
    width: 54px;
    cursor: pointer;
    text-align: center;
}

.asheng-tcgl-td-img {
    height: 42px;
    width: 42px;
}

.asheng-tcgl-td-text {
    width: 60px;
    font-size: 14px;
    margin-top: 2px;
}

.asheng-dlgl {
    height: 21px;
    font-size: 15px;
    font-weight: bold;
    color: #333333;
    line-height: 21px;
    padding-top: 17px;
    padding-bottom: 20px;
    text-align: left;
}

.asheng-dlgl-td-img {
    height: 26px;
    width: 26px;
}

.menus-div {
    width: 98%;
    margin-left: 1%;
    border-radius: 10px 10px 10px 10px;
    background: #ffffff;
    margin-top: 10px;
    overflow: hidden;
}

.menus-table {
    width: 94%;
    margin-left: 3%;
}

.menus-tcgl {
    font-size: 16px;
    font-weight: bold;
    margin-top: -5px;
}

.menus-tcgl-detail {
    font-size: 14px;
    margin-top: 6px;
    color: #666666;
}

.menus-tcgl-detail-btn {
    background-color: yellowgreen;
    font-size: 10px;
    color: white;
    padding: 2px 10px;
    margin-left: 3px;
    border-radius: 20px;
}

.menus-title {
    width: 94%;
    margin-left: 3%;
    color: #39383e;
    font-size: 16px;
    font-weight: bold;
    padding-top: 17px;
}

.menus-table-text-center {
    text-align: center;
    margin-top: 13px;
}

.exclusive-domain {
    background-color: white;
}

.exclusive-domain-table {
    width: 96%;
    margin-left: 2%;
}

.exclusive-domain-table-title {
    font-size: 14px;
    color: #333333;
    line-height: 22px;
    margin-bottom: 12px;
}

.exclusive-domain-table-domain {
    font-size: 14px;
    color: #a1a1a1;
    line-height: 20px;
    word-break: break-all;
}

.asheng-url-sty {
    cursor: pointer;
}

.asheng-url-sty:hover {
    text-decoration: underline;
}

/* 公告 */
.asheng-gg-detail {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.4);
    max-width: 500px;
    transform: translate(-50%, -50%);
}

#asheng-gg-detail-dlalog {
    position: absolute;
    width: 90%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 16px 16px 16px 16px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: 20px;
    overflow: hidden;
}

#asheng-gg-detail-title {
    line-height: 25px;
    text-align: center;
    background: #fdf6f0;
    color: #c66919;
    font-size: 16px;
    padding: 10px 40px;
    font-weight: bold;
}

#asheng-gg-detail-text {
    height: 300px;
    width: 94%;
    margin-left: 3%;
    margin-top: 10px;
    overflow-x: hidden;
    overflow-y: auto;
}

/* 公告 */


.asheng-custom-dlglog {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
}

.asheng-custom-dlglog-inner {
    background-color: white;
    width: 90%;
    text-align: center;
    position: fixed;
    top: 50%;
    overflow: hidden;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%)
}

.asheng-custom-dlglog-title {
    font-weight: bold;
    font-size: 15px;
    height: 40px;
    line-height: 40px;
    background-color: #eee;
}

.asheng-custom-dlglog-ok-button {
    height: 40px;
    line-height: 40px;
    font-size: 15px;
    border-top: 1px solid #eee;
    color: rgb(25, 124, 255);
    cursor: pointer;
}

.asheng-list-add-button {
    position: fixed;
    bottom: 0px;
    height: 50px;
    line-height: 50px;
    width: 100%;
    text-align: center;
    font-size: 15px;
    background-color: #1989fa;
    z-index: 8;
    color: white;
    cursor: pointer;
    letter-spacing: 2px;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%)
}

.asheng-custom-dlglog-close-btn {
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 10px;
    font-size: 20px;
    margin-right: 10px;
}

.asheng-custom-dlglog-content {
    width: 96%;
    margin-left: 2%;
}

.top-asheng-custom-title {
    background-color: white;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9;
    width: 100%;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%)
}

.top-asheng-custom-title-bottom-div {
    height: 50px;
    width: 100%;
    clear: both;
}

.top-asheng-custom-title-back {
    width: 80px;
    font-weight: normal;
    font-size: 14px;
    color: #1989fa;
    cursor: pointer;
}

.top-asheng-custom-title-back-text {
    margin-left: 3px;
}

.custom-show-share-qrcode {
    height: 40px;
    line-height: 40px;
    background: linear-gradient(90deg, #57C6FF, #009FDE);
    color: white;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}
</style>