<template>
    <div class="asheng-shop-share-div">
        <img src="../../../../assets/image/tgbj.png" style="width: 350px;height: 622px;" />
        <div style="width: 100%;position: relative; top: -220px;">
            <div id="asheng-dlglog-shop-qrcode-div"></div>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2-fix';
export default {
    name: "shopShareImage",
    props: {
        id: {
            type: Number
        }
    },
    data() {
        return {

        }
    },
    created() {
        this.url = window.location.protocol + "//" + window.location.host + "/prod-api/h5/wxauth/authorize?wxstate=" + this.id;
    },
    mounted() {
        let url = this.url;
        new QRCode(document.getElementById("asheng-dlglog-shop-qrcode-div"), {
            text: url,
            height: 140,
            width: 140
        });
    },
    methods: {

    }
}

</script>
<style scoped>
.asheng-shop-share-div {
    width: 350px;
    height: 622px;
    overflow: hidden;
}

#asheng-dlglog-shop-qrcode-div {
    width: 140px;
    height: 140px;
    margin-left: 92px;
    border: 15px solid white;
}
</style>