import request from '@/utils/request'

export function saveOrder(pa) {
    return request({
        url: '/h5/client/order/save',
        method: 'post',
        data:pa,
        T:"P"
    })
}


