import request from '@/utils/request'

export function getShopByOpenid(pa) {
    return request({
        url: '/h5/client/shop/getShopByOpenid',
        method: 'get',
        params:pa,
        T:"P"
    })
}

export function getBankAccountList(pa) {
    return request({
        url: '/h5/client/shop/bankAccount/list',
        method: 'get',
        params:pa,
        T:"P"
    })
}

export function addBankAccount(pa) {
    return request({
        url: '/h5/client/shop/bankAccount/add',
        method: 'post',
        data:pa,
        T:"P"
    })
}

export function addCashApply(pa) {
    return request({
        url: '/h5/client/shop/cashApply/add',
        method: 'post',
        data:pa,
        T:"P"
    })
}

export function getCashApplyList(pa) {
    return request({
        url: '/h5/client/shop/cashApply/list',
        method: 'get',
        params:pa,
        T:"P"
    })
}

export function getCommissionList(pa) {
    return request({
        url: '/h5/client/shop/commission/list',
        method: 'get',
        params:pa,
        T:"P"
    })
}

export function getChildShopList(pa) {
    return request({
        url: '/h5/client/shop/child/list',
        method: 'get',
        params:pa,
        T:"P"
    })
}

export function getSchoolListByOpenid(pa) {
    return request({
        url: '/h5/client/shop/school/listByOpenid',
        method: 'get',
        params:pa,
        T:"P"
    })
}

export function addSchool(pa) {
    return request({
        url: '/h5/client/shop/school/add',
        method: 'post',
        data:pa,
        T:"P"
    })
}

export function getSimilarList(pa) {
    return request({
        url: '/h5/client/shop/school/getSimilar',
        method: 'get',
        params:pa,
        T:"P"
    })
}