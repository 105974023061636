<template>
    <div>
        <div style="height: 500px;width: 100%;background-color: #f7f7f7;">
            <div style="height: 50px;line-height: 50px;">选择学校范围</div>
            <div style="width: 100%;background-color: white;">
                <van-search style="width: 94%;margin-left: 3%;" v-model="schoolName" placeholder="请输入学校名称" />
            </div>
            <div class="school-items">
                <div class="school-item" v-for="item in schoolList" :key="item.id" @click="schoolChooseOk(item)">
                    <div v-if="chooseSchoolId == item.id" style="color: red;">{{ item.schoolName }}
                        <font style="float: right;color: red;font-size: 20px;"><van-icon name="success" /></font>
                    </div>
                    <div v-else>{{ item.schoolName }}</div>
                </div>
            </div>
            <div style="height: 10px;margin-top: 10px;">
                <div style="width: 92%;margin-left:4%;height: 35px;line-height: 30px;text-align: left;font-size: 14px;">
                    <font v-if="chooseSchoolName">已选择学校：<font style="color: red;">{{
                        chooseSchoolName }}</font>
                    </font>
                    <font style="color: #999;" v-else>选择学校后，匹配更精确，非必选。</font>
                </div>
                <van-button @click="chooseOkCreateOrder" round type="primary" style="width: 94%;"><del>￥{{ good.oldPrice ?
                    good.oldPrice : 0.00 }}</del>
                    ￥{{ good.price ? good.price : 0.00 }}立即抽取{{
                        good.paperNum
                        ?
                        good.paperNum : 0
                    }}次</van-button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.asheng-top-tips {
    text-align: center;
    font-size: 14px;
    color: #999;
    padding-left: 3%;
    padding-right: 3%;
    margin-bottom: 15px;
}

.asheng-upload-btn {
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 112px;
    border: 1px dotted #eee;
}

.asheng-zj-table {
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
}

.asheng-zj-photo-text {
    font-size: 12px;
    text-align: left;
    width: 100%;
    clear: both;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #999;
}

.school-items {
    text-align: left;
    background-color: white;
    height: 280px;
    overflow-y: auto;
}

.school-item {
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    margin-right: 20px;
    border-top: 1px solid #eee;
    font-size: 15px;
    cursor: pointer;
}
</style>

<script>
import { getSchoolList } from '@/api/phone/school'
export default {
    name: "chouquPaper",
    props: {
        good: {
            default: null,
            type: Object
        }
    },
    watch: {
        schoolName(newValue) {
            this.schoolList = []
            if (this.orginSchoolList.length > 0) {
                for (let i = 0; i < this.orginSchoolList.length; i++) {
                    let sc = this.orginSchoolList[i]
                    if (sc.schoolName.indexOf(newValue) > -1) {
                        this.schoolList.push(sc)
                    }
                }
            }
        }
    },
    data() {
        return {
            chooseSchoolId: null,
            chooseSchoolName: null,
            schoolName: "",
            orginSchoolList: [],
            schoolList: [],
        }
    },
    created() {
        getSchoolList({}).then(res => {
            this.orginSchoolList = res.data
            this.schoolList = []
            if (this.orginSchoolList.length > 10) {
                for (let i = 0; i < this.orginSchoolList.length; i++) {
                    this.schoolList.push(this.orginSchoolList[i])
                }
            }
        })
    },
    methods: {
        schoolChooseOk(row) {
            this.chooseSchoolId = row.id
            this.chooseSchoolName = row.schoolName
        },
        chooseOkCreateOrder() {
            this.$emit("chouQuPaperCreateOrder", this.chooseSchoolId, this.good.goodCode)
        }
    }
}
</script>