let isWx = true
//正式配置
let currDomain = "https://sc.ynzsj.cn";
let baseApiUrl = currDomain+"/prod-api";

//测试配置
//let baseApiUrl = "http://192.168.0.137:8080"

export {
    currDomain,
    baseApiUrl,
    isWx
}
