import axios from 'axios'
import { getToken } from '@/utils/auth'
import { showToast } from 'vant';
import { baseApiUrl } from '@/config'

export function adminUploadExcelFile(file,successFun){
    // 设置请求头信息
    const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + getToken("A")
    }
    let uploadUrl = baseApiUrl + "/system/finance/yjTableUpload"
    commUpload(uploadUrl,file, headers, successFun)
}

export function adminUploadFile(file,successFun){
    // 设置请求头信息
    const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + getToken("A")
    }
    let uploadUrl = baseApiUrl + "/common/upload"
    commUpload(uploadUrl,file, headers, successFun)
}

export function phoneUploadFile(file,successFun){
    // 设置请求头信息
    const headers = {
        'Content-Type': 'multipart/form-data'
    }
    let uploadUrl = baseApiUrl + "/h5/client/upload"
    commUpload(uploadUrl, file, headers, successFun)
}

function commUpload(uploadUrl, file, headers, successFun) {
    const formData = new FormData();
    formData.append("file", file)

    axios.post(uploadUrl, formData, {headers}).then(res => {
        //console.info("上传文件返回", res)
        if (res.status === 200) {
            showToast("上传成功")
            //console.info("上传文件成功", res.data)
            successFun(res.data.data,res.data)
        } else {
            showToast({message:res.msg, duration:3000})
        }
    })
}
