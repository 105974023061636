import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
// 1. 引入你需要的组件
import Vant from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';
import store from './utils/store'
import hasPermi from './utils/haspermi'
import VueQuillEditor from 'vue-quill-editor'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

const app = createApp(App)

app.config.globalProperties.$hasPermi = hasPermi
app.use(router).use(store).use(Vant).use(VueQuillEditor).mount('#app');

router.beforeEach((to, from, next) => {
    console.log("to", to.path)
    next();
})