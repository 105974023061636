import { createRouter, createWebHistory } from 'vue-router'

import phoneRoutes from '@/router/phone'

//用户端
import PhoneIndex from '../views/phone/index/index.vue'
let routes = [{
    path:'/',
    name:'/',
    component: PhoneIndex
},{
    path:'/phone',
    name:'/phone',
    component: PhoneIndex
}]


routes = routes.concat(phoneRoutes)

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior: () => ({ top: 0 }),
    routes: routes
})

export default router;