<template>
    <div>
        <div style="height: 1px;width: 100%;"></div>
        <div v-if="list.length > 0">
            <div class="item-order" v-for="item in list" v-bind:key="item.id">
                <table class="item-order-table">
                    <tr>
                        <td colspan="2" class="asheng-margin"></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="item-order-tag">我推广的店铺信息</div>
                        </td>
                        <td class="item-order-status">状态：{{ item.status === 0 ? '可用' : item.status === 2 ? '待支付开通' : '已停用'
                        }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="asheng-good-name">于{{ item.createTime ? item.createTime : "" }}提交填写店铺信息<span
                                style="color: red;">，{{ item.passTime ? item.passTime : "无"
                                }}开通</span></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="asheng-margin" style="height: 1px;"></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="item-order-content-div" style="line-height: 25px;">
                                <table class="item-order-content-table" style="padding-top: 15px;padding-bottom: 15px;">
                                    <tr>
                                        <td>店铺名：</td>
                                        <td style="text-align: right;">{{ item.shopName ? item.shopName : "无"
                                        }}</td>
                                    </tr>
                                    <tr>
                                        <td>店铺号码：</td>
                                        <td style="text-align: right;">{{ item.phone ? item.phone : "无"
                                        }}</td>
                                    </tr>
                                    <tr>
                                        <td>累计赚取：</td>
                                        <td style="text-align: right;">{{ item.shopTotalFee ? item.shopTotalFee : "0"
                                        }}元</td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="asheng-margin"></td>
                    </tr>
                </table>
            </div>
        </div>
        <div v-else style="margin-top: 20px; background-color: white;margin-left: 2%;width: 96%;padding: 20px 0px;">
            <van-icon name="warning-o" style="font-size: 40px;color: #999;" />
            <div style="color: #999;">暂无记录</div>
        </div>
        <div style="height: 20px;clear: both;width: 100%;"></div>
    </div>
</template>

<script>
import { showLoadingToast } from 'vant';
import { getChildShopList } from '@/api/phone/shop'

export default {
    name: "childShop",
    data() {
        return {
            list: []
        }
    },
    created() {

        let rid = this.$route.query.rid
        const toast = showLoadingToast({
            duration: 0,
            message: '查询中...',
            forbidClick: true
        });
        getChildShopList({ recommendShopId: rid }).then(res => {
            this.list = res.data
            console.info("list", this.list)
            toast.close()
        })
    },
    methods: {
        onClickLeft() {
            this.$emit("closeTxRec")
        }
    }
}

</script>

<style scoped>
table,
tr,
td {
    border: 0px;
}

.asheng-good-name {
    font-size: 16px;
    color: #000000;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
}

.asheng-margin {
    height: 15px;
}

.item-order {
    background-color: white;
    width: 94%;
    margin-left: 3%;
    margin-top: 15px;
    border-radius: 10px;
    text-align: left;
}

.item-order-table {
    width: 94%;
    margin-left: 3%;
}

.item-order-tag {
    background-color: #0086F7;
    border-radius: 5px;
    width: 130px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    color: white;
    font-size: 14px;
}

.item-order-status {
    color: #0086F7;
    font-size: 15px;
    text-align: right;
    width: 50%;
}

.item-order-content-div {
    background-color: #F9FAFE;
    border-radius: 5px;
}

.item-order-content-table {
    width: 94%;
    margin-left: 3%;
    font-size: 15px;
}
</style>