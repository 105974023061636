
import axios from 'axios';
import { tansParams } from "@/utils/parautil";
import { baseApiUrl } from "@/config";
import { getToken } from '@/utils/auth'
import cache from '@/utils/cache'
import { closeToast, showToast } from 'vant';

const timeout = 120000

axios.defaults.headers = {"Content-Type":"application/json;charset=utf-8"}
// axios 配置
var instance = axios.create({
  timeout: timeout,	//请求超时时间
  withCredentials: false, //允许请求携带cookie信息
});


// 添加请求拦截器
instance.interceptors.request.use(config => {// 在发送请求之前做些什么，比如传token
    if (config.T === "A") {
      config.url = baseApiUrl + config.url
    }else if (config.T === "P") {
      config.url = baseApiUrl + config.url
    }
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  
    if (getToken(config.T) && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken(config.T) // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
      const requestObj = {
        url: config.url,
        data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
        time: new Date().getTime()
      }
      const sessionObj = cache.session.getJSON('sessionObj')
      if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
        cache.session.setJSON('sessionObj', requestObj)
      } else {
        const s_url = sessionObj.url;                  // 请求地址
        const s_data = sessionObj.data;                // 请求数据
        const s_time = sessionObj.time;                // 请求时间
        const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
        if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
          const message = '数据正在处理，请勿重复提交';
          console.warn(`[${s_url}]: ` + message)
          return Promise.reject(new Error(message))
        } else {
          cache.session.setJSON('sessionObj', requestObj)
        }
      }
    }
    return config
  }, error => {// 对请求错误做些什么
  console.log(error) // for debug
  //closeToast();
  console.log('err' + error);
})

// 添加响应拦截器
instance.interceptors.response.use(
  response => {//对请求响应做点什么
    //closeToast();
    // debugger
    let resultData = response.data
    if (resultData.code === 401) {
      showToast("登录过期")
      
      //return Promise.reject("登录过期");
    }else if (resultData.code === 403) {
      showToast("没有权限访问该功能")
      //return Promise.reject("没有权限访问该功能");
    }

    return resultData;
  }, error => {// 对响应错误做点什么
    //closeToast();
    console.log('err' + error);  // for debug
    //showToast(error)
    //return Promise.reject(error);
  });

export default instance;
