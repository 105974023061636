import request from '@/utils/request'

export function getGoodList(query) {
    return request({
        url: '/h5/client/good/list',
        method: 'get',
        params:query,
        T:"P"
    })
}


