import Cookies from 'js-cookie'

const AdminTokenKey = 'Admin-Token'
const PhoneTokenKey = 'Phone-Token'
const SellerTokenKey = 'Seller-Token'

export function getToken(T) {
  return Cookies.get(getTokenKey(T))
}

export function setToken(T,token) {
  return Cookies.set(getTokenKey(T), token)
}

export function removeToken(T) {
  console.info("移除token："+T)
  return Cookies.remove(getTokenKey(T))
}

export function getTokenKey(T) {
    if (T === "A") {
        return AdminTokenKey;
    }
    else if (T === "P") {
        return PhoneTokenKey;
    }
    else if (T === "S") {
        return SellerTokenKey;
    }
}