<template>
    <div>
        <template v-if="cqList != null && cqList.length > 0">
            <div class="fr-items" v-for="( item, index ) in  cqList " v-bind:key="item.id">
                <div class="fr-items-inner">
                    <div style="clear: both;height: 10px;width: 100%;"></div>
                    <div>
                        <font style="color: #4169E1;margin-right: 5px;">[{{ item.gender === 1 ? '男' : '女' }}生]</font>{{
                            item.schoolName }}
                    </div>
                    <div class="fr-item-line"></div>
                    <div style="margin-top: 6px;" v-if="item.qqNumber">{{ item.qqNumber }} <font class="item-copy-btn"
                            @click="copyContent(item.qqNumber)">复制
                        </font>
                    </div>
                    <div style="margin-top: 10px;" v-if="item.wxQrcode">
                        <img v-for="url in item.wxQrcodeList" :src="url" v-bind:key="url" @click="onClickYulanImgUrl(url)"
                            style="height: 100px;margin-right: 5px;cursor: pointer;" />
                    </div>
                    <div style="margin-top: 6px;color: #666;" v-if="item.note">{{ item.note }}</div>
                    <div style="margin-top: 6px;">{{ item.gender === 1 ? '他' : '她' }}留的微信号：{{ item.wxNumber }} <font
                            class="item-copy-btn" @click="copyContent(item.wxNumber)">复制
                        </font>
                    </div>
                    <div style=" clear: both;width: 100%;margin-top: 10px;font-size: 12px;color:red;">若搜不到，可以申请更换，24小时内处理。
                    </div>
                    <div class="fr-item-line"></div>
                    <div style="margin-top: 10px;font-size: 13px;">
                        <div class="fr-item-btn" style="background-color: #333;"
                            @click="onclickDelExtracPaper(item.id, index)">
                            删除记录</div>
                        <div class="fr-item-btn" v-if="item.status === 0" style="background-color: #808A87;"
                            @click="onclickUpdateStatus(item.id, index, 2)">申请更换</div>
                        <div class="fr-item-btn" v-if="item.status === 2" style="background-color: #666;width:120px;"
                            @click="onclickUpdateStatus(item.id, index, 0)">撤销更换申请</div>
                        <div class="fr-item-btn" v-if="item.status === 4" style="background-color: #228B22;"
                            @click="onclickResetPaper(item.id, index)">重新抽取</div>
                    </div>

                    <div style="clear: both;height: 15px;width: 100%;"></div>
                </div>
            </div>
            <div style="height: 1px;width: 100%;"></div>
        </template>
        <template v-else>
            <div style="height: 200px;width: 100%;font-size: 13px;margin-top: 20px;color: #999;">你还没有抽取过交友纸条</div>
        </template>

        <div v-if="yulanImgUrlDialogShow"
            style="width: 100%;height: 100%;position: fixed;top: 0px;left: 0px;z-index: 999999;background-color: #333;overflow: hidden;">
            <div style="height: 40px;color: white;text-align: right;margin-top: 15px;margin-right: 15px;cursor: pointer;">
                <span style="padding: 2px 4px;background-color: red;" @click="yulanImgUrlDialogShow = false">关闭</span>
            </div>
            <div style="text-align: center;">
                <img :src="yulanImgUrl" style="max-width: 400px;max-height: 600px;" />
            </div>
        </div>
    </div>
</template>

<style scoped>
.fr-items {
    text-align: left;
    font-size: 14px;
    width: 94%;
    margin-left: 3%;
    margin-top: 10px;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
}

.fr-items-inner {
    width: 92%;
    margin-left: 4%;
}

.fr-item-line {
    height: 1px;
    background-color: #eee;
    width: 100%;
    margin-top: 10px;
    clear: both;
}

.fr-item-btn {
    width: 80px;
    height: 25px;
    line-height: 25px;
    color: white;
    text-align: center;
    border-radius: 25px;
    cursor: pointer;
    float: right;
    margin-right: 8px;
}

.item-copy-btn {
    font-size: 10px;
    background-color: #FF8000;
    padding: 2px 4px;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}
</style>cqList

<script>
import { queryUserPaperExtractList, userPaperExtracDelete, userPaperExtracUpdateStatus, userPaperExtracReset } from '@/api/phone/paper'
import { showConfirmDialog, showToast } from 'vant'

export default {
    name: "MyFr",
    components: {

    },
    props: {
        openid: {
            default: "",
            type: String
        }
    },
    data() {
        return {
            cqList: [],
            yulanImgUrl: "",
            yulanImgUrlDialogShow: false
        }
    },
    created() {
        this.initCurrPage();
    },
    methods: {
        initCurrPage() {
            queryUserPaperExtractList({ openid: this.openid }).then(res => {
                this.cqList = res.data
                for (const key in this.cqList) {
                    let element = this.cqList[key];
                    if (element.wxQrcode) {
                        let wxQrcodeStr = decodeURIComponent(element.wxQrcode)
                        this.cqList[key].wxQrcodeList = wxQrcodeStr.split(",")
                    }
                }
            })
        },
        onClickYulanImgUrl(url) {
            this.yulanImgUrl = url
            this.yulanImgUrlDialogShow = true
        },
        onclickDelExtracPaper(id, index) {
            showConfirmDialog({
                title: '操作提示',
                message:
                    '确定要删除该条记录吗？',
            }).then(() => {
                //确认销毁
                userPaperExtracDelete({ id: id, openid: this.openid }).then(res => {
                    if (res.code === 200) {
                        this.cqList.splice(index, 1)
                    } else {
                        showToast({ type: "text", message: res.msg })
                    }
                })
            }).catch(() => {
                // on cancel
            });
        },
        //更新状态
        onclickUpdateStatus(id, index, status) {
            let statusStr = status == 2 ? '申请更换纸条' : '撤销更换纸条申请';
            if (status === 0) {
                showConfirmDialog({
                    title: '操作提示',
                    message:
                        '确定要' + statusStr + '吗？',
                }).then(() => {
                    userPaperExtracUpdateStatus({ id: id, status: status, openid: this.openid }).then(res => {
                        if (res.code === 200) {
                            this.cqList[index].status = status
                        } else {
                            showToast({ type: "text", message: res.msg })
                        }
                    })
                }).catch(() => {
                    // on cancel
                });
            } else {
                userPaperExtracUpdateStatus({ id: id, status: status, openid: this.openid }).then(res => {
                    if (res.code === 200) {
                        this.cqList[index].status = status
                    } else {
                        showToast({ type: "text", message: res.msg })
                    }
                })
            }
        },

        //重新抽取纸条
        onclickResetPaper(id, index) {
            userPaperExtracReset({ id: id, openid: this.openid }).then(res => {
                if (res.code === 200) {
                    this.cqList[index] = res.data
                } else {
                    showToast({ type: "text", message: res.msg })
                }
            })
        },

        //复制
        copyContent(fz) {
            navigator.clipboard.writeText(fz)
            showToast("复制成功");
        },
    }
}
</script>