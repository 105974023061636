<template>
    <div>
        <div class="asheng-c-menus">
            <div style="margin-top: 10px;">
                <span class="asheng-c-menus-span" @click="showXuZhiContent = true">玩家须知</span>|<span
                    class="asheng-c-menus-span" @click="showKeFuContent = true">联系客服</span>|<span
                    class="asheng-c-menus-span" @click="showKeFuContent = true">投诉举报</span>
            </div>
            <div style="height: 20px;width: 100%;clear: both;"></div>
        </div>

        <van-popup v-model:show="showXuZhiContent" round position="bottom">
            <div style="height: 50px;line-height: 50px;font-weight: bold;border-bottom: 1px solid #eee;">玩家须知</div>

            <div style="line-height: 22px;margin-left: 3%;width: 94%;margin-top: 10px;">
                <div style="color: red;font-size: 15px;">★投放纸条请保持直诚直实★</div>
                <div style="color: red;font-size: 15px;">★销毁纸条请点放入的纸条★</div>
                <div style="color: red;font-size: 16px;">【遇到搜索不到的纸条，请点抽中记录的更换】</div>
                <div style="color: rgb(187, 0, 255);font-size: 13px;">
                    所有纸条均来自网友，学校只是定位选择，并不代表一定是这个学校的人，<span style="color: red;">禁止色情</span>，如有违法均由发布者承担，我们不保证所有纸条内容的真实性。
                </div>
                <div style="color: rgb(187, 0, 255);font-size: 13px;">在聊天时，请一定要仔细甄别，切勿轻易转账以防被骗，<span
                        style="color: red;">谨防骗色骗钱</span>，本平台不承担任何法律责任。
                </div>
                <div style="color: rgb(187, 0, 255);font-size: 13px;">禁止利用本平台做违法事件，我们将保留追究法律责任的权利。</div>
            </div>
            <div style="margin-top: 20px;margin-bottom: 20px;">
                <van-button type="primary" style="width: 94%;" @click="onclickOk">我知道了</van-button>
            </div>
        </van-popup>

        <van-popup v-model:show="showKeFuContent" round position="bottom" closeable>
            <div style="height: 50px;line-height: 50px;font-weight: bold;border-bottom: 1px solid #eee;">截图保存，识别添加客服</div>
            <div style="line-height: 22px;margin-left: 3%;width: 94%;margin-top: 10px;">
                <img :src="'icon/qq.png'" style="width: 200px;" />
                <h3>QQ：2979733200</h3>
                <div style="height: 30px;width: 100%;"></div>
            </div>
        </van-popup>
    </div>
</template>
<script>

export default {
    name: "indexA",
    components: {

    },
    props: {
        defaultShow: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            showXuZhiContent: false,
            showKeFuContent: false
        }
    },
    created() {
        if (this.defaultShow) {
            this.showXuZhiContent = true
        }
    },
    methods: {
        onclickOk() {
            this.showXuZhiContent = false
            //this.$emit("updateShowXuZhi")
        }
    }
}

</script>
<style scoped>
.asheng-c-menus {
    font-size: 14px;
    /* position: fixed;
    bottom: 0px; */
    text-align: center;
    width: 100%;
}

.asheng-c-menus-span {
    margin-right: 5px;
    margin-left: 5px;
    color: #5B5B5B;
    /* text-decoration: underline; */
    cursor: pointer;
    font-size: 14px;
}
</style>