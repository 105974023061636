<template>
  <div class="index-back">
    <template v-if="activied != 'chouqu'">
      <table class="tab-table">
        <tr>
          <td @click="onclickTab('fangru')" :class="activied === 'fangru' ? 'tab-td-activied' : 'tab-td'">
            <!-- <img class="tab-table-icon" :src="'/icon/1.png'"> -->
            <span class="tab-table-text">放入的纸条</span>
          </td>
          <td @click="onclickTab('choudao')" :class="activied === 'choudao' ? 'tab-td-activied' : 'tab-td'">
            <!-- <img class="tab-table-icon" :src="'/icon/chou.png'"> -->
            <span class="tab-table-text">抽到的纸条</span>
          </td>
          <td @click="onclickTab('chouqu')" :class="activied === 'chouqu' ? 'tab-td-activied' : 'tab-td'">
            <!-- <img class="tab-table-icon" :src="'/icon/fang.png'"> -->
            <span class="tab-table-text">抽个对象</span>
          </td>
          <td @click="onclickTab('kaidian')" :class="activied === 'kaidian' ? 'tab-td-activied' : 'tab-td'">
            <!-- <img class="tab-table-icon" :src="'/icon/shop.png'"> -->
            <span class="tab-table-text">开个分店</span>
          </td>
        </tr>
      </table>
      <div style="height: 50px;width: 100%;"></div>
    </template>

    <div v-if="openid">
      <!-- 我放入的纸条 -->
      <template v-if="activied === 'fangru'">
        <my-fangru :openid="openid"></my-fangru>
      </template>

      <!-- 我抽到的纸条 -->
      <template v-if="activied === 'choudao'">
        <my-choudao :openid="openid"></my-choudao>
      </template>

      <!-- 抽个对象 -->
      <template v-if="activied === 'chouqu'">
        <div id="custom-home">
          <div style="height: 30px;"></div>
          <div>
            <!-- <div class="my-swipe-div" :style="'height:' + swiperHeight + 'px;'">
              <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white"
                :style="'height:' + swiperHeight + 'px;'">
                <van-swipe-item v-for="item in advImgList" v-bind:key="item" :style="'height:' + swiperHeight + 'px;'">
                  <img style="width:100%;height:100%;border-radius: 10px;overflow: hidden;" :src="item">
                </van-swipe-item>
              </van-swipe>
            </div> -->
            <div id="custom-home-top-tips-msg">
              <table>
                <tr>
                  <td>
                    <div style="float: left;margin-left: 25px;" class="custom-home-top-tips">本平台不提供任何信息</div>
                  </td>
                  <td rowspan="4">
                    <img style="float: right;" src="../../../assets/image/v2/bj_2.png" height="134px" width="127px">
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style="float: left;margin-left: 50px;" class="custom-home-top-tips">所有信息均是用户发布</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style="float: left;margin-left: 0px;" class="custom-home-top-tips">本平台只提供匹配服务</div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div style="float: left;margin-left: 75px;" class="custom-home-top-tips">费用均是匹配的服务费</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style="float: left;margin-left: 10px;" class="custom-home-top-tips">如遇匹配到虚假信息</div>
                  </td>
                  <td>

                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div style="float: right;margin-left: 10px;margin-top: -20px;" class="custom-home-top-tips">
                      请点击底部联系客服删除</div>
                  </td>
                </tr>
              </table>
            </div>
            <div style="height: 20px;clear: both;"></div>
            <table id="custom-home-center-meun-table">
              <tr>
                <td @click="onclickTab('fangru')">
                  <div class="custom-home-center-meun">
                    <div class="custom-home-center-meun-img">
                      <img src="../../../assets/image/v2/bj_fr.png" />
                    </div>
                    <div class="custom-home-center-meun-text">
                      放入的纸条
                    </div>
                  </div>
                </td>

                <td @click="onclickTab('choudao')">
                  <div class="custom-home-center-meun">
                    <div class="custom-home-center-meun-img">
                      <img src="../../../assets/image/v2/bj_my.png" />
                    </div>
                    <div class="custom-home-center-meun-text">
                      抽到的纸条
                    </div>
                  </div>
                </td>

                <td @click="onclickTab('chouqu')">
                  <div class="custom-home-center-meun">
                    <div class="custom-home-center-meun-img">
                      <img src="../../../assets/image/v2/bj_cq.png" />
                    </div>
                    <div class="custom-home-center-meun-text">
                      抽个对象
                    </div>
                  </div>
                </td>

                <td @click="onclickTab('kaidian')">
                  <div class="custom-home-center-meun">
                    <div class="custom-home-center-meun-img">
                      <img src="../../../assets/image/v2/bj_fd.png" />
                    </div>
                    <div class="custom-home-center-meun-text">
                      开个分店
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div style="height: 10px;clear: both;"></div>
            <div id="custom-home-bottom-content">
              <div style="height: 12px;clear: both;"></div>
              <div id="custom-home-fr-cq-btn">
                <table style="width: 100%;">
                  <tr>
                    <td style="width: 3px;"></td>
                    <td>
                      <div class="custom-center-max-btn" @click="onclickTab('fangru')">
                        留的纸条
                      </div>
                    </td>
                    <td style="width: 3px;"></td>
                    <td>
                      <div class="custom-center-max-btn" @click="onclickTab('choudao')"
                        style="color: white; background: linear-gradient(90deg, #F6A2E1 0%, #5A6FF9 100%);border-radius:20px;">
                        抽中记录
                      </div>
                    </td>
                    <td style="width: 3px;"></td>
                  </tr>
                </table>
              </div>
              <div style="height: 20px;clear: both;"></div>
              <table style="width: 100%;">
                <tr>
                  <td style="width: 3%;"></td>
                  <td>
                    <div class="custom-nan custom-hezi">
                      <div class="custom-hezi-headimg">
                        <img src="../../../assets/image/v2/bj_nan.png" />
                      </div>
                      <div class="custom-hezi-title"><i>男生盒子</i></div>
                      <div class="custom-add-btn" @click="onClickAddGood(1)"
                        style="background: linear-gradient(90deg, #72E99C, #24954B);">放入1张男生纸条</div>
                      <div class="custom-cq-btn" @click="onClickGetGood('sex_male_1')">抽取1张男生纸条</div>
                      <div class="custom-cq-more">
                        <div class="cq-s-div cs-s-div-5" @click="onClickGetGood('sex_male_5')">抽5次</div>
                        <div class="cq-s-div cs-s-div-10" @click="onClickGetGood('sex_male_10')">抽10次</div>
                      </div>
                      <div style="width: 100%;height: 20px; clear: both;"></div>
                    </div>
                  </td>
                  <td style="width: 3%;"></td>
                  <td>
                    <div class="custom-nv custom-hezi">
                      <div class="custom-hezi-headimg">
                        <img src="../../../assets/image/v2/bj_nv.png" />
                      </div>
                      <div class="custom-hezi-title"><i>女生盒子</i></div>
                      <div class="custom-add-btn" @click="onClickAddGood(2)"
                        style="background: linear-gradient(270deg, #FF7A95, #FFB696);">放入1张女生纸条</div>
                      <div class="custom-cq-btn" @click="onClickGetGood('sex_female_1')">抽取1张女生纸条</div>
                      <div class="custom-cq-more">
                        <div class="cq-s-div cs-s-div-5" @click="onClickGetGood('sex_female_5')">抽5次</div>
                        <div class="cq-s-div cs-s-div-10" @click="onClickGetGood('sex_female_10')">抽10次</div>
                      </div>
                      <div style="width: 100%;height: 20px; clear: both;"></div>
                    </div>
                  </td>
                  <td style="width: 3%;"></td>
                </tr>
              </table>

              <!-- 放入 -->
              <van-popup v-model:show="showAddPaper" round position="bottom" closeable>
                <add-paper v-if="showAddPaper" :sex="sex" :good="addPaperGood" :openid="openid"
                  @addPaperOrder="addPaperOrder"></add-paper>
              </van-popup>

              <!-- 抽取开始 -->
              <van-popup v-model:show="showChouQuPaper" round position="bottom" closeable>
                <chouqu-paper v-if="showChouQuPaper" @chouQuPaperCreateOrder="chouQuPaperCreateOrder"
                  :good="chouQuPaperGood"></chouqu-paper>
              </van-popup>
              <!-- 抽取结束 -->

            </div>

            <!-- <bottom-index :defaultShow="defaultXuZhiShow"></bottom-index> -->
          </div>
        </div>
      </template>

      <!-- 我要开分店 -->
      <template v-if="activied === 'kaidian'">
        <my-shop :openid="openid" @addShopOrder="addShopOrder"></my-shop>
      </template>
    </div>
    <div v-else>加载中,请稍后...</div>

    <div style="height: 15px;clear: both;"></div>
    <bottom-index :defaultShow="defaultXuZhiShow"></bottom-index>
  </div>
</template>

<script>
import AddPaper from '../components/index/addPaper'
import ChouquPaper from '../components/index/chouquPaper'
import { currDomain, isWx } from '@/config'
import { getGoodList } from '@/api/phone/good'
import { saveOrder } from '@/api/phone/order'
import { showToast } from 'vant'
import MyFangru from '../components/order/myfr'
import MyChoudao from '../components/order/mycd'
import MyShop from '../components/shop/myshop'
import BottomIndex from '../components/bottom/index'

export default {
  name: "indexA",
  components: {
    AddPaper,
    MyFangru,
    MyChoudao,
    MyShop,
    BottomIndex,
    ChouquPaper
  },
  data() {
    return {
      openid: null,
      shopid: null,
      glist: [],
      swiperHeight: 200,
      advImgList: [],
      showAddPaper: false,
      sex: 2,
      addPaperGood: {},
      activied: "",
      defaultXuZhiShow: false,
      defaultXuZhiFlag: false,
      orderType: -1,
      homeHeight: 500,
      showChouQuPaper: false,
      chouQuPaperGood: null
    }
  },
  created() {
    this.initPage()

    this.homeHeight = window.innerHeight;
  },
  methods: {
    initPage() {
      this.advImgList = ['icon/seller_benner.png']
      this.setSwipeWidth()

      let shopId = this.$route.query.sid
      if (shopId) {
        console.info("推荐店铺不为空：", shopId)
        localStorage.setItem("shopid", shopId);
      }

      let localOpenid = localStorage.getItem("openid")
      if (!localOpenid) {
        let openid = this.$route.query.oid
        if (openid) {
          console.info("openid不为空：", openid)
          let flag = localStorage.getItem("openidFlag")
          if (flag == "true") {
            console.info("openid获取标识为：", true)
            this.setOpenid(openid)
            localStorage.setItem("openid", openid);
          } else {
            console.info("openid获取标识为：", false)
            this.linkWxAuth()
          }
        } else {
          this.linkWxAuth()
        }
      } else {
        this.setOpenid(localOpenid)
      }

      let activiedFlag = localStorage.getItem("activied")
      if (activiedFlag) {
        this.activied = activiedFlag
      } else {
        this.activied = "chouqu"
      }

      if (this.activied == "chouqu" && this.defaultXuZhiFlag == false) {
        this.defaultXuZhiShow = true
        this.defaultXuZhiFlag = true
      }
    },
    setSwipeWidth() {
      var pmkd = document.body.clientWidth
      //console.info("宽度：" + pmkd)
      this.swiperHeight = pmkd * 0.438
    },
    setOpenid(openid) {
      this.openid = openid

      getGoodList().then(res => {
        console.info("微信：", res)
        this.glist = res.data
      })
    },
    linkWxAuth() {
      localStorage.setItem("openidFlag", "true")
      console.info("进入跳转微信，跳转微信标识为：", isWx)
      if (isWx) {
        window.location.href = currDomain + "/prod-api/h5/wxauth/authorize"
      } else {
        this.setOpenid("oO8sn6FasvDxGY0ZXUTsh9rZBRRs22222")
      }
    },
    createOrder(cqSchoolId, orderType, goodCode, userIncreasePaper, shop) {
      this.orderType = -1
      const toast1 = showToast({ type: "loading", message: "加载中", forbidClick: true, duration: 0 })
      if (shop) {
        shop.openid = this.openid
      }
      let pa = { cqSchoolId: cqSchoolId, orderType: orderType, openid: this.openid, goodCode: goodCode, paper: userIncreasePaper, shop: shop }
      saveOrder(pa).then(res => {
        toast1.close();
        if (res.code == 200) {
          if (this.showAddPaper) {
            this.showAddPaper = false
          }
          if (this.showChouQuPaper) {
            this.showChouQuPaper = false
          }
          if (res.data.needPayFlag === 1) {
            console.info("订单号：", res.data.orderNo)
            const config = res.data.paras; // 从服务器获取到微信配置参数

            if (config && typeof WeixinJSBridge !== 'undefined') {
              this.initWxPayment(config);
            } else {
              document.addEventListener('WeixinJSBridgeReady', () => {
                this.initWxPayment(config);
              });
            }
          } else {
            showToast({ type: "text", message: "操作成功" })
          }

          this.orderType = orderType
        } else {
          showToast({ type: "text", message: "失败，请重试" })
        }
      })
    },
    initWxPayment(config) {
      let that = this;
      config.package = config.packageVal
      WeixinJSBridge.invoke('getBrandWCPayRequest', { ...config }, (result) => {
        if (result.errMsg === "get_brand_wcpay_request:ok") {
          // 支付成功处理逻辑
          console.info("支付成功")

          //根据orderType做操作
          if (that.orderType == 1) {
            //添加纸条
            that.activied = "fangru"
          } else if (that.orderType == 2) {
            //抽取纸条
            that.activied = "choudao"
          } else if (that.orderType == 3) {
            //开通店铺
            that.activied = "kaidian"
          }
        } else {
          // 支付失败处理逻辑
          console.info("支付失败")
        }
      });

      WeixinJSBridge.on("closeWindow", function () {
        // 页面被关闭时的处理逻辑
      });
    },
    //点击增加按钮
    onClickAddGood(gender) {
      this.sex = gender

      for (let i = 0; i < this.glist.length; i++) {
        let element = this.glist[i]
        if (gender === 1 && element.goodCode === 'add_sex_male_1') {
          //男
          this.addPaperGood = element
          break;
        }

        if (gender === 2 && element.goodCode === 'add_sex_female_1') {
          //女
          this.addPaperGood = element
          break;
        }
      }

      this.showAddPaper = true
    },
    //点击抽取按钮
    onClickGetGood(goodCode) {
      for (let i = 0; i < this.glist.length; i++) {
        let element = this.glist[i]
        if (element.goodCode === goodCode) {
          this.chouQuPaperGood = element
          break;
        }
      }
      this.showChouQuPaper = true
    },
    chouQuPaperCreateOrder(cqSchoolId, goodCode) {
      this.createOrder(cqSchoolId, 2, goodCode, null)
    },
    //填写纸条信息后返回
    addPaperOrder(paper) {
      this.createOrder(null, 1, this.addPaperGood.goodCode, paper)
    },
    onclickTab(val) {
      this.activied = val
      localStorage.setItem("activied", this.activied)
    },
    //填写店铺信息返回
    addShopOrder(shop) {
      //抽取纸条
      this.activied = "chouqu"
      shop.recommendShopId = this.shopid
      this.createOrder(null, 3, "add_shop_1", null, shop)
    }
  }
}


</script>

<style scoped>
.good-item-list {
  background-color: #70f3ff;
  cursor: pointer;
}


.my-swipe-div {
  width: 94%;
  margin-left: 3%;
  overflow: hidden;
}

.my-swipe .van-swipe-item {
  font-size: 20px;
  width: 100%;
  height: 200px;
  text-align: center;
}

.custom-hezi-headimg {
  width: 100%;
}

.custom-hezi-headimg img {
  height: 58px;
  width: 58px;
  border-radius: 50%;
  box-shadow: 0px 5px 15px #5DC6FF;
  margin-top: -10px;
}

.custom-hezi-title {
  font-size: 18px;
  font-weight: bold;
  color: #0086E3;
  height: 40px;
  line-height: 40px;
  letter-spacing: 2px;
}

.custom-cq-more {
  width: 80%;
  height: 45px;
  line-height: 45px;
  margin: 10px auto;
  font-size: 13px;
  color: white;
  font-weight: bold;
}

.cs-s-div-5 {
  float: left;
}

.cs-s-div-10 {
  float: right;
}

.cq-s-div {
  width: 46%;
  background: linear-gradient(90deg, #5DC6FF, #A541FF);
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #FFFFFF;
}

.custom-add-btn {
  width: 80%;
  margin-left: 10%;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  font-size: 14px;
  margin-top: 5px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #FFFFFF;
}

.custom-cq-btn {
  width: 80%;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  font-size: 14px;
  background: linear-gradient(90deg, #57C6FF, #009FDE);
  margin: 10px auto;
  color: white;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #FFFFFF;
}

.custom-hezi {
  width: 100%;
  border-radius: 10px;
  border: 2px solid #eee;
}

.custom-nan {
  background: linear-gradient(0deg, #C9E2FF 0%, #D5F0FF 25%, #ACE5FF 100%);
}

.custom-nv {
  background: linear-gradient(0deg, #DBC3FF 0%, #EBDFFF 100%);
}

.custom-nv {
  background-color: blue;
}

.tab-table {
  width: 100%;
  top: 0px;
  position: fixed;
  z-index: 999;
  height: 35px;
  background-color: white;
  font-weight: bold;
  text-align: center;
}

.tab-td {
  font-size: 14px;
  color: #333;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
}

.tab-td-activied {
  font-size: 14px;
  cursor: pointer;
  color: red;
  height: 40px;
  line-height: 40px;
}

.tab-table-icon {
  height: 14px;
  float: left;
  margin-top: 13px;
}

.tab-table-text {}

/** 
v2版本结束
*/
#custom-home {
  height: 100%;
  width: 100%;
  background: url(../../../assets/image/v2/bg_1.png)
}

#custom-home-top-tips-msg {
  width: 94%;
  margin-left: 3%;
  overflow: hidden;
}

#custom-home-top-tips-msg table {
  width: 100%;
}

.custom-home-top-tips {
  height: 27px;
  line-height: 27px;
  background: linear-gradient(84deg, #BFD7FD 0%, #DD8AB7 100%);
  border-radius: 13px 13px 0px 13px;
  border: 1px solid #FFFFFF;
  color: white;
  font-size: 14px;
  padding: 0px 15px;
}

#custom-home-center-meun-table {
  width: 94%;
  margin-left: 3%;
  overflow: hidden;
}

.custom-home-center-meun {
  text-align: center;
}

.custom-home-center-meun-img img {
  height: 42px;
  height: 42px;
}

.custom-home-center-meun-text {
  font-weight: bold;
  font-size: 14px;
  color: #5B5B5B;
}


#custom-home-bottom-content {
  border-radius: 25px 25px 0px 0px;
  border-top: 1px solid #FFFFFF;
  background: linear-gradient(180deg, #FFD5FA, #eee);
}

#custom-home-fr-cq-btn {
  width: 94%;
  margin-left: 3%;
  overflow: hidden;
  height: 53px;
  background: #FFFFFF;
  border-radius: 26px;
}

.custom-center-max-btn {
  margin-bottom: 15px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  margin-top: 4px;

}

/** 
v2版本结束
*/
</style>
