<template>
    <div>
        <div style="height: 50px;line-height: 50px;font-weight: bold;">放入1张{{ sex == 1 ?
            '男生' :
            '女生'
        }}纸条</div>

        <div style="width: 100%;border-top: 1px solid #eee;">
            <van-field label-width="60" v-model="form.schoolName" is-link readonly label="学校" placeholder="请选择学校，必填"
                @click="showSchoolChoose = true" />
            <van-field type="textarea" rows="4" label-width="60" v-model="form.note" name="" label=""
                placeholder="这里是内容输入框，可以在这里填写你想要填写的东西(比如兴趣爱好等等)，最好把微信号、QQ号都留下，避免被人搜不到你的联系方式！必填" />
            <div style="text-align:left;margin:10px 15px;">
                <van-uploader v-model="wxQrcodeList" :preview-size="[100, 100]" :max-count="2"
                    :before-read="wxQrcodeReadBeforeHandle">
                    <div class="asheng-upload-btn">
                        <van-icon name="plus" style="font-size: 40px;color: #ccc;" />
                    </div>
                </van-uploader>

                <div class="asheng-zj-photo-text">点击+号，上传<font style="background-color: red;color: white;">{{ sex == 1 ?
                    '男生' :
                    '女生'
                }}微信二维码</font>和照片
                </div>
            </div>
            <div style="margin:0px 15px; border-bottom:1px solid #eee;"></div>
            <van-field label-width="60" v-model="form.wxNumber" label="微信号" placeholder="在这里填写微信号，必填" />
            <van-field label-width="60" v-model="form.qqNumber" label="QQ号" placeholder="在这里填写QQ号" />

            <div style="margin-top: 15px;">
                <div v-if="sex === 2 && count < 1" class="asheng-top-tips">
                    <font style="color: red;">女生可以免费放入1次。</font>
                </div>
                <div v-else style="text-align: center;font-size: 15px;font-weight: bold;">
                    {{ good.price }}元/次
                </div>
            </div>

            <div style="height: 80px;margin-top: 15px;">
                <van-button round type="primary" style="width: 94%;" @click="addPaperOk">确认放入纸条</van-button>
            </div>
        </div>

        <van-popup v-model:show="showSchoolChoose" round position="bottom">
            <div style="height: 100%;width: 100%;background-color: #f7f7f7;">
                <div style="height: 50px;line-height: 50px;">选择学校</div>
                <div style="width: 100%;background-color: white;">
                    <van-search style="width: 94%;margin-left: 3%;" v-model="schoolName" placeholder="请输入学校名称" />
                </div>
                <div class="school-items">
                    <div class="school-item" v-for="item in schoolList" :key="item.id" @click="schoolChooseOk(item)">{{
                        item.schoolName }}</div>
                </div>
                <div style="height: 10px;margin-top: 10px;">
                    <!-- <van-button round type="primary" style="width: 94%;" @click="showSchoolChoose = false">确 定</van-button> -->
                </div>
            </div>
        </van-popup>
    </div>
</template>

<style scoped>
.asheng-top-tips {
    text-align: center;
    font-size: 14px;
    color: #999;
    padding-left: 3%;
    padding-right: 3%;
    margin-bottom: 15px;
}

.asheng-upload-btn {
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 112px;
    border: 1px dotted #eee;
}

.asheng-zj-table {
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
}

.asheng-zj-photo-text {
    font-size: 12px;
    text-align: left;
    width: 100%;
    clear: both;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #999;
}

.school-items {
    text-align: left;
    background-color: white;
    height: 430px;
    overflow-y: auto;
}

.school-item {
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    margin-right: 20px;
    border-top: 1px solid #eee;
    font-size: 15px;
}
</style>

<script>
import { countByOpenid } from '@/api/phone/paper'
import { getSchoolList } from '@/api/phone/school'
import { phoneUploadFile } from '@/utils/upload'
import { baseApiUrl } from '@/config'
import { showToast } from 'vant';
export default {
    name: "addPaper",
    props: {
        sex: {
            default: 2,
            type: Number
        },
        good: {
            default: null,
            type: Object
        },
        openid: {
            default: "",
            type: String
        }
    },
    watch: {
        schoolName(newValue) {
            this.schoolList = []
            if (this.orginSchoolList.length > 0) {
                for (let i = 0; i < this.orginSchoolList.length; i++) {
                    let sc = this.orginSchoolList[i]
                    if (sc.schoolName.indexOf(newValue) > -1) {
                        this.schoolList.push(sc)
                    }
                }
            }
        }
    },
    data() {
        return {
            wxQrcodeList: [],
            form: {},
            showSchoolChoose: false,
            schoolName: "",
            orginSchoolList: [],
            schoolList: [],
            count: 1
        }
    },
    created() {
        getSchoolList({}).then(res => {
            this.orginSchoolList = res.data
            this.schoolList = []
            if (this.orginSchoolList.length > 10) {
                for (let i = 0; i < this.orginSchoolList.length; i++) {
                    this.schoolList.push(this.orginSchoolList[i])
                }
            }
        })

        if (this.sex === 2) {
            countByOpenid({ openid: this.openid }).then(res => {
                if (res.status === 200 && res.data) {
                    this.count = res.data
                }
            })
        }
    },
    methods: {
        wxQrcodeReadBeforeHandle(file) {
            let that = this
            phoneUploadFile(file, function (res) {
                console.info("url=", res.url)
                let img = { url: baseApiUrl + res.url, isImage: true }
                console.info(JSON.stringify(img))
                that.wxQrcodeList.push(img)
            })
        },
        schoolChooseOk(row) {
            this.form.schoolId = row.id
            this.form.schoolName = row.schoolName
            this.showSchoolChoose = false
        },
        addPaperOk() {
            if (!this.form.schoolName) {
                showToast("请选择学校")
                return;
            }
            if (!this.form.note) {
                showToast("请在内容输入框填写内容")
                return;
            }
            if (this.wxQrcodeList == null || this.wxQrcodeList.length <= 0) {
                showToast("请上传照片")
                return;
            }
            if (!this.form.wxNumber) {
                showToast("请填写微信号")
                return;
            }

            this.form.openid = this.openid
            this.form.gender = this.sex
            if (this.wxQrcodeList && this.wxQrcodeList.length > 0) {
                let wxQrcode = "";
                for (const key in this.wxQrcodeList) {
                    let ele = this.wxQrcodeList[key];
                    if (wxQrcode === "") {
                        wxQrcode = ele.url
                    } else {
                        wxQrcode = wxQrcode + "," + ele.url
                    }
                }
                this.form.wxQrcode = wxQrcode
            }

            this.$emit("addPaperOrder", this.form)
        }
    }
}
</script>