<template>
    <div>
        <div style="text-align: left;width: 94%;margin-left: 3%;font-size: 15px;padding-top: 10px;">
            提现提交后，我们会尽快处理，最晚不超过24小时。请耐心等待。如有疑问，请联系客服。
        </div>
        <div v-if="list.length > 0">
            <div class="item-order" v-for="item in list" v-bind:key="item.id">
                <table class="item-order-table">
                    <tr>
                        <td colspan="2" class="asheng-margin"></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="item-order-tag">提现申请信息</div>
                        </td>
                        <td class="item-order-status">状态：{{ item.status === 1 ? '待审核' : item.status === 2 ? '提现失败' : '提现成功'
                        }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="asheng-good-name">{{ item.createTime ? item.createTime : "" }}，申请提现<span
                                style="color: black;font-weight: bold;font-size: 18px;">{{
                                    item.amount ?
                                    item.amount : "0" }}元</span></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="asheng-margin" style="height: 1px;"></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="item-order-content-div" style="line-height: 25px;">
                                <table class="item-order-content-table" style="padding-top: 15px;padding-bottom: 15px;">
                                    <tr>
                                        <td>收款账号：</td>
                                        <td style="text-align: right;">{{ item.bankCardNumber ? item.bankCardNumber : ""
                                        }}</td>
                                    </tr>
                                    <tr>
                                        <td>收款名：</td>
                                        <td style="text-align: right;">{{ item.bankAccountName ? item.bankAccountName : ""
                                        }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>开户银行：</td>
                                        <td style="text-align: right;">{{ item.bankName ? item.bankName : "" }}</td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                    </tr>

                    <tr style="font-size: 14px;">
                        <td colspan="2" style="padding-top: 10px;"><span>处理进度：</span><span style="color: red;">{{
                            item.status === 1 ? '已成功提交申请，24小时内审核完成' : item.status === 2 ? '提现失败，失败原因：' + item.failMsg :
                            '于' + item.completeTime + '提现成功，如有问题，请联系客服。'
                        }}</span></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="asheng-margin"></td>
                    </tr>
                </table>
            </div>
        </div>
        <div v-else style="margin-top: 20px; background-color: white;margin-left: 2%;width: 96%;padding: 20px 0px;">
            <van-icon name="warning-o" style="font-size: 40px;color: #999;" />
            <div style="color: #999;">暂无记录</div>
        </div>
        <div style="height: 20px;clear: both;width: 100%;"></div>
    </div>
</template>

<script>
import { showLoadingToast } from 'vant';
import { getCashApplyList } from '@/api/phone/shop'

export default {
    name: "txRec",
    data() {
        return {
            list: []
        }
    },
    created() {

        let openid = localStorage.getItem("openid")
        const toast = showLoadingToast({
            duration: 0,
            message: '查询中...',
            forbidClick: true
        });
        getCashApplyList({ openid: openid }).then(res => {
            this.list = res.data
            console.info("list", this.list)
            toast.close()
        })
    },
    methods: {
        onClickLeft() {
            this.$emit("closeTxRec")
        }
    }
}

</script>

<style scoped>
table,
tr,
td {
    border: 0px;
}

.asheng-good-name {
    font-size: 15px;
    color: #000000;
    padding-top: 10px;
    padding-bottom: 10px;
}

.asheng-margin {
    height: 15px;
}

.item-order {
    background-color: white;
    width: 94%;
    margin-left: 3%;
    margin-top: 15px;
    border-radius: 10px;
    text-align: left;
}

.item-order-table {
    width: 94%;
    margin-left: 3%;
}

.item-order-tag {
    background-color: #0086F7;
    border-radius: 5px;
    width: 100px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    color: white;
    font-size: 14px;
}

.item-order-status {
    color: #0086F7;
    font-size: 15px;
    text-align: right;
    width: 50%;
}

.item-order-content-div {
    background-color: #F9FAFE;
    border-radius: 5px;
}

.item-order-content-table {
    width: 94%;
    margin-left: 3%;
    font-size: 15px;
}
</style>