<template>
    <div>
        <div style="text-align: left;width: 94%;margin-left: 3%;font-size: 15px;padding-top: 10px;">
            你添加的学校信息，会<span style="color: blueviolet;">在审核通过之后开放使用。我们会在24小时内审核处理</span>。如果急需，请联系客服审核或添加。
        </div>
        <div v-if="list != null && list.length > 0">
            <div class="item-order" v-for="item in list" v-bind:key="item.id">
                <table class="item-order-table">
                    <tr>
                        <td colspan="2" class="asheng-margin"></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="item-order-tag">学校信息</div>
                        </td>
                        <td class="item-order-status">状态：{{ item.status === 0 ? '正常使用' : item.status === 2 ? '待审核' :
                            item.status === 3 ? '未通过' :
                                '已停用'
                        }}
                        </td>
                    </tr>

                    <tr>
                        <td colspan="2" class="asheng-margin" style="height: 1px;"></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="item-order-content-div" style="line-height: 25px;">
                                <table class="item-order-content-table" style="padding-top: 15px;padding-bottom: 15px;">
                                    <tr>
                                        <td>学校名称：</td>
                                        <td style="text-align: right;">{{ item.schoolName ? item.schoolName : ""
                                        }}</td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                    </tr>

                    <tr style="font-size: 14px;">
                        <td colspan="2" style="padding-top: 10px;"><span>审核进度：</span><span>{{
                            item.status === 2 ? '已成功提交申请，24小时内审核完成' : item.status === 3 ? '未通过审核。未通过原因：' + item.failMsg
                            :
                            '审核成功，开放使用中。'
                        }}</span></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="asheng-margin"></td>
                    </tr>
                </table>
            </div>
        </div>
        <div v-else
            style="color: #666; line-height: 80px;border-radius: 10px; margin-top: 50px;width: 94%;margin-left: 3%;background-color: white;">
            你还没有添加过学校信息
        </div>
        <div style="height: 80px;clear: both;width: 100%;"></div>

        <!--添加学校信息-->
        <van-popup v-model:show="showAddSchool" round position="bottom" style="min-height: 60%;">
            <div style="height: 50px;line-height: 50px;font-weight: bold;border-bottom: 1px solid #eee;">添加学校<span
                    style="font-weight: normal;font-size: 14px;">(请填写学校全称)</span></div>
            <div style="line-height: 22px;margin-left: 3%;width: 94%;margin-top: 10px;">
                <van-field label-width="60" v-model="form.schoolName" label="学校名称" placeholder="请填写学校名称" />
                <div v-if="schoolName" style="margin-top: 15px;width: 94%;text-align: left;font-size: 14px;">
                    相似学校：<span style="color: red;">{{ schoolName }}</span>
                </div>
                <div style="height: 80px;margin-top: 15px;">
                    <van-button round type="primary" style="width: 94%;" @click="onclickAddSchoolOk">保存</van-button>
                </div>

            </div>
        </van-popup>
        <!--添加学校信息-->

        <div style="width: 100%;position: fixed;bottom: 0px;left: 0px;z-index: 99;">
            <van-button icon="plus" type="primary" style="width: 94%;margin-bottom: 20px;"
                @click="onclickAddSchoolHandle">添加</van-button>
        </div>
    </div>
</template>

<script>
import { showLoadingToast, showToast } from 'vant';
import { addSchool, getSchoolListByOpenid, getSimilarList } from '@/api/phone/shop'

export default {
    name: "addSchool",
    data() {
        return {
            list: [],
            form: {},
            showAddSchool: false,
            openid: "",
            schoolName: ""
        }
    },
    created() {
        let openid = localStorage.getItem("openid")
        this.openid = openid
        this.getSchoolList()
    },
    watch: {
        'form.schoolName'(newVal) {
            this.similarSchool(newVal)
        }
    },
    methods: {
        getSchoolList() {
            const toast = showLoadingToast({
                duration: 0,
                message: '查询中...',
                forbidClick: true
            });
            getSchoolListByOpenid({ openid: this.openid }).then(res => {
                this.list = res.data
                console.info("list", this.list)
                toast.close()
            })
        },
        onclickAddSchoolHandle() {
            this.showAddSchool = true
            this.form = { openid: this.openid }
        },
        onclickAddSchoolOk() {
            addSchool(this.form).then(res => {
                if (res.code == 200) {
                    this.getSchoolList()
                    this.showAddSchool = false
                } else {
                    showToast(res.msg)
                }
            })
        },
        similarSchool(val) {
            //console.info("啥玩意：", val)
            getSimilarList({ schoolName: val }).then(res => {
                if (res.data) {
                    let schoolName = null;
                    for (let i = 0; i < res.data.length; i++) {
                        if (i > 10) {
                            break;
                        }
                        const element = res.data[i];
                        if (schoolName) {
                            schoolName = schoolName + "," + element.schoolName
                        } else {
                            schoolName = element.schoolName
                        }
                    }
                    this.schoolName = schoolName;
                }
            })
        }
    }
}

</script>

<style scoped>
table,
tr,
td {
    border: 0px;
}

.asheng-good-name {
    font-size: 15px;
    color: #000000;
    padding-top: 10px;
    padding-bottom: 10px;
}

.asheng-margin {
    height: 15px;
}

.item-order {
    background-color: white;
    width: 94%;
    margin-left: 3%;
    margin-top: 15px;
    border-radius: 10px;
    text-align: left;
}

.item-order-table {
    width: 94%;
    margin-left: 3%;
}

.item-order-tag {
    background-color: #0086F7;
    border-radius: 5px;
    width: 100px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    color: white;
    font-size: 14px;
}

.item-order-status {
    color: #0086F7;
    font-size: 15px;
    text-align: right;
    width: 50%;
}

.item-order-content-div {
    background-color: #F9FAFE;
    border-radius: 5px;
}

.item-order-content-table {
    width: 94%;
    margin-left: 3%;
    font-size: 15px;
}
</style>