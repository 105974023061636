import store from "./store";

//自定义指令
const hasPermi = function (value) {
    let flag = false;
    // 在元素添加到DOM后执行的逻辑
    let perms = store.state.permissions
    let roles = store.state.roles
    if (roles && roles.indexOf("admin")>-1) {
        return true;
    }
    if (perms.includes("*:*:*")) {
        return true;
    }
    if (perms.includes(value)) {
        return true;
    }
        
    return flag;
}
export default hasPermi