import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'

const store = new Vuex.Store({
    state: {
        user: {},
        permissions: [],
        roles: [],
        seller: {},
        sellerPermissions: [],
        sellerRoles: [],
    },
    mutations: {
        SET_ADMIN_SHIMING: (state, shiming) => {
            state.user.isshiming = shiming;
        },
        SET_SELLER_SHIMING: (state, shiming) => {
            state.seller.isshiming = shiming;
        },
        SET_USER: (state, user) => {
            state.user = user;
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions
        },
        SET_ROLES:(state, roles) => {
            state.roles = roles
        },
        SET_SELLER: (state, seller) => {
            state.seller = seller;
        },
        SET_SELLER_PERMISSIONS: (state, sellerPermissions) => {
            state.sellerPermissions = sellerPermissions
        },
        SET_SELLER_ROLES:(state, sellerRoles) => {
            state.sellerRoles = sellerRoles
        },
    },
    plugins: [persistedState ()]
})

export default store;