import PhoneIndex from '../views/phone/index/index.vue'
import TxRec from '@/views/phone/components/shop/TxRec.vue';
import YjRec from '@/views/phone/components/shop/YjRec.vue';
import Childshop from '@/views/phone/components/shop/ChildShop.vue'
import School from '@/views/phone/components/shop/School.vue'

const phoneRoutes = [{
    path:'/phone/index',
    name:'/phone/index',
    component: PhoneIndex
},{
    path:'/phone/txrec',
    name:'/phone/txrec',
    component: TxRec
},{
    path:'/phone/yjrec',
    name:'/phone/yjrec',
    component: YjRec
},{
    path:'/phone/childshop',
    name:'/phone/childshop',
    component: Childshop
},{
    path:'/phone/school',
    name:'/phone/school',
    component: School
}]


export default phoneRoutes;